import { USER_TOKEN, URL_BASE } from "../constants";
import { postRequest as post, handleErrors, getRequest, patchRequest, postRequest } from "../requests";
import { loginUser, logout as logoutUser } from '../../redux/modules/auth/authActions'
import { showSnackbar } from "../../redux/modules/snackbars/snackbarActions";
import store from "../../redux/globalStore";

export async function isFirstTimeInPortal(email) {
    const payload = await getRequest(`${URL_BASE}/account/first-login`, { email });
    return payload.data.isFirstTime;
}

export async function hasLoggedUser() {
    const states = store.getState();
    const isLoggedUser = states.auth.isAuthenticated;

    if (isLoggedUser) {
        return true;
    }

    if (window.sessionStorage.getItem(USER_TOKEN) !== null) {
        const token = window.sessionStorage.getItem(USER_TOKEN);
        store.dispatch(loginUser(token));
        return true;
    }

    if (window.localStorage.getItem(USER_TOKEN) !== null) {
        const token = window.localStorage.getItem(USER_TOKEN);
        store.dispatch(loginUser(token));
        return true;
    }

    return false;
}

export async function login(email, password, keepSession) {
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
        store.dispatch(showSnackbar('warning', "E-mail is empty or invalid."));
        return;
    }

    if (!password || password === "") {
        store.dispatch(showSnackbar('warning', "Password is empty."));
        return;
    }

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const payload = await post(`${URL_BASE}/account/login/portal`, formData, null,
        (error) => {
            const { status, data } = error;
            handleErrors(status, data);
        });
    if (payload.success) {
        store.dispatch(loginUser(payload.data));
        window.localStorage.setItem(USER_TOKEN, payload.data);
    } else {
        store.dispatch(showSnackbar('default', "E-mail or password isn't correct"));
    }
}

export async function logout() {
    window.localStorage.removeItem(USER_TOKEN);
    window.sessionStorage.removeItem(USER_TOKEN);
    store.dispatch(logoutUser());

    window.location.href = "/";
}

export async function refreshToken() {
    const token = store.getState().auth.loggedUser;
    if (token) {
        try {
            const payload = await getRequest(`${URL_BASE}/account/refresh`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            store.dispatch(loginUser(payload.data));
            window.localStorage.setItem(USER_TOKEN, payload.data);
        } catch (e) { }
    }
}

export async function changePassword(emailAddress, password, confirmPassword) {
    if (confirmPassword !== password) {
        store.dispatch(showSnackbar('warning', 'Password is not confirmed.'));
        return;
    }
    const payload = await patchRequest(`${URL_BASE}/account/first-time/password`, { emailAddress, password }, null,
        (error) => {
            const { status, data } = error;
            handleErrors(status, data);
        });
    if (payload.success) {
        await login(emailAddress, password, false);
    }
}

export async function sendNotifications(emailAddress, phoneNumber, fullName, zipCode, password) {
    // Validação inicial do e-mail
    if (!emailAddress.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
        store.dispatch(showSnackbar("warning", "Invalid e-mail address. Please, verify your e-mail address and try again."));
        return;
    }

    // Validação inicial do número de telefone
    if (phoneNumber.match(/^[+]*[(]{0,1}[0-9]{3}[)]{0,1}[\\s]{0,1}[-\\./0-9]{3}[-]{1}[-\\./0-9]{4}$/)) {
        store.dispatch(showSnackbar("warning", "Invalid phone number. Please, verify your phone number and try again."));
        return;
    }

    try {
        // Envia os dados para a API
        const response = await postRequest(`${URL_BASE}/account/services/register/website`, {
            emailAddress,
            phoneNumber,
            fullName,
            zipCode,
            password
        });

        // Verifica se o campo `success` é `false` na resposta
        if (!response.success) {
            // Passa os erros para o handleErrors
            handleErrors(400, response); // Assume que `response` contém `status` e `data`
            return;
        }

        // Exibe mensagem de sucesso
        store.dispatch(showSnackbar(
            'success',
            'Congratulations on successfully registering! You can now log in to the platform using your registered email and password to start accessing all its features. Enjoy your experience!'
        ));
    } catch (error) {
        // Lida com erros da requisição (problemas de rede, etc.)
        const { status, data } = error;
        handleErrors(status, data);
    }
}
