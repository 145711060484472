import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, DialogContent, Dialog, DialogContentText, DialogTitle, TextField, Grid, Checkbox, FormControlLabel, DialogActions, Button, MenuItem } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import { saveDemandData, cleanConfirmationCode } from '../../redux/modules/demands/demandsActions';
import { showSnackbar } from '../../redux/modules/snackbars/snackbarActions';
import { ZipCodeField } from '../../components/zip-code-field/ZipCodeField';
import PhoneNumberField from '../../components/phone-number-field/PhoneNumberField';
import { createDemandAndAccount, askForConfirmationCode } from '../../api/services/demandsServices';
import { isUserValid } from './validateUserData';
import { useHistory } from 'react-router-dom';
import check from '../../assets/check.png';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    thankYouTitle: {
        fontSize: '80px',
        color: '#1A237E', // Azul escuro
        fontWeight: 'bold'
    },
};

const ModalUserData = ({ show, category, subcategory, requirements, isFullTime, expectedCode, cleanConfirmationCode, onClose, showSnackbar, classes }) => {
    const [zipCode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+1'); // Defina countryCode aqui
    const [fullname, setFullname] = useState('');
    const [waitingConfirmation, setWaitingConfirmation] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState([]);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [showThankYouModal, setShowThankYouModal] = useState(false); // Estado para controlar a exibição da modal "Thank you"


    const history = useHistory();

    useEffect(() => {
        setZipCode('');
        setEmail('');
        setPhoneNumber('');
        setCountryCode('');
        setFullname('');
        setWaitingConfirmation(false);
        setConfirmationCode([]);
        setTermsAgreed(false);
    }, [show]);

    function registerServices() {
        const fullPhoneNumber = countryCode + phoneNumber; // Concatenando código do país e número de telefone
        if (waitingConfirmation) {
            if (confirmationCode.join(' ') === expectedCode) {
                const reqs = requirements.filter(r => r.toUpperCase().includes('ADDITIONAL INFORMATION'));
                let description = requirements.filter(r => r.toUpperCase().includes('ADDITIONAL INFORMATION'))[0];

                if (!description) {
                    description = 'No requirement';
                }

                createDemandAndAccount(fullname, email, fullPhoneNumber.replace(/\D/g, ''), category, subcategory, description,
                    isFullTime, reqs, zipCode)
                    .then(() => {
                        setShowThankYouModal(true); // Mostrar a modal "Thank you" ao concluir o registro

                        //showSnackbar('success', 'To next steps, look at your mailbox.');
                        /*onClose();
                        history.push('/');*/
                    });
            } else {
                showSnackbar('warning', 'Invalid Code');
            }
        } else {
            if (isUserValid(zipCode, fullPhoneNumber, fullname, email)) {
                askForConfirmationCode(fullPhoneNumber, email)
                    .then((code) => {
                        setWaitingConfirmation(true);
                    });
            }
        }
    }

    const handleCloseThankYouModal = () => {
        setShowThankYouModal(false); // Fechar a modal "Thank you"
        history.push('/');
    };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };

    return (
        <Dialog open={show} onClose={() => {
            cleanConfirmationCode();
            onClose();
        }}>
            <DialogTitle>We are almost there</DialogTitle>
            <DialogContent>
                {
                    waitingConfirmation ?
                        <WaitForConfirmationCode email={email} countryCode={countryCode} phoneNumber={phoneNumber} confirmationCode={confirmationCode} setConfirmationCode={setConfirmationCode} />
                        :
                        <BodyFormUser email={email} fullname={fullname} countryCode={countryCode} phoneNumber={phoneNumber} zipCode={zipCode}
                            setEmail={setEmail} setFullname={setFullname} setCountryCode={setCountryCode} setPhoneNumber={setPhoneNumber} setZipCode={setZipCode} />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAgreed}
                            onChange={(e) => setTermsAgreed(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="I agree to receive validation codes by WhatsApp and email."
                />
            </DialogContent>
            <DialogActions>
                {termsAgreed && (
                    <Button variant="contained" color="secondary" onClick={registerServices}>
                        Search
                    </Button>
                )}
            </DialogActions>

            {/* Modal "Thank you" */}
            <Dialog open={showThankYouModal} onClose={handleCloseThankYouModal} maxWidth="xl">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <img src={check} alt="Imagem" style={{ width: '100px', height: '100px' }} />
                    </div>
                    <DialogTitle className={classes.thankYouTitle}>Thank You!</DialogTitle>
                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <DialogContent>
                            <DialogContentText>Your application was successfully submitted.</DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <DialogContentText>Professionals in your area have received your demand.</DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <DialogContentText>
                                You can manage your open demands by clicking on the <b>"My Open Demands"</b> option in the <b>"Your Profile"</b> dropdown menu.</DialogContentText>
                        </DialogContent>
                    </div>
                    <DialogActions>
                        <Button onClick={handleCloseThankYouModal} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>

        </Dialog>
    );
}


const BodyFormUser = ({ zipCode, countryCode, phoneNumber, fullname, email, setZipCode, setCountryCode, setPhoneNumber, setFullname, setEmail }) => {
    return (
        <>
            <DialogContentText>Don't waste your time calling various professional. Fill in this fields and <b>we will find the greatest professionals to you</b>!</DialogContentText>
            <Grid container>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} style={{ paddingRight: 5 }}>

                        <TextField
                                    margin="normal"
                            select
                            variant="outlined"
                            label="Country Code"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            fullWidth
                            style={{ height: '100%' }}
                        >
                            <MenuItem value="+1">USA (+1)</MenuItem>
                            <MenuItem value="+55">Brazil (+55)</MenuItem>
                            <MenuItem value="+52">Mexico (+52)</MenuItem>
                            <MenuItem value="+56">Chile (+56)</MenuItem>
                            <MenuItem value="+54">Argentina (+54)</MenuItem>
                            <MenuItem value="+598">Uruguay (+598)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingRight: 5 }}>
                        <TextField
                            margin="normal"
                            id="phone-number"
                            variant="outlined"
                            label="Phone Number"
                            InputLabelProps={{ shrink: true }}
                            type="tel"
                            onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                            value={phoneNumber}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        id="fullname"
                        variant="outlined"
                        label="Full Name"
                        type="text"
                        onChange={({ target }) => setFullname(target.value)}
                        value={fullname}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        id="email"
                        variant="outlined"
                        label="Email Address"
                        type="email"
                        onChange={({ target }) => setEmail(target.value)}
                        value={email}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingRight: 5 }}>
                    <TextField
                        margin="normal"
                        autoFocus
                        id="zipcode"
                        variant="outlined"
                        label="Zip Code"
                        type="text"
                        onChange={({ target }) => setZipCode(target.value)}
                        value={zipCode}
                        fullWidth
                        InputProps={{ inputComponent: ZipCodeField }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const WaitForConfirmationCode = ({ phoneNumber, email, confirmationCode, setConfirmationCode }) => {
    let confirm1 = React.createRef(),
        confirm2 = React.createRef(),
        confirm3 = React.createRef(),
        confirm4 = React.createRef();

    return (
        <>
            <DialogContentText>To verify your account, enter the code we sent to your phone number by <b>WhatsApp and email</b>: {phoneNumber} - {email}</DialogContentText>
            <Grid container>
                <Grid item xs={3} style={{ paddingRight: 5 }}>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="confirmation-0"
                        variant="outlined"
                        type="number"
                        inputRef={confirm1}
                        onInput={({ target }) => {
                            target.value = Math.max(0, parseInt(target.value)).toString().slice(0, 1);
                        }}
                        onChange={({ target }) => {
                            const confirmation = confirmationCode;
                            confirmation[0] = target.value;
                            setConfirmationCode(confirmation);
                            if (target.value.length >= 1) {
                                confirm2.current.focus();
                            }
                        }}

                        inputProps={{ style: { textAlign: 'center' } }}
                        value={confirmationCode[0]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3} style={{ paddingRight: 5 }}>
                    <TextField
                        margin="normal"
                        id="confirmation-1"
                        variant="outlined"
                        type="number"
                        inputRef={confirm2}
                        style={{ paddingRight: 5 }}
                        onInput={({ target }) => {
                            target.value = Math.max(0, parseInt(target.value)).toString().slice(0, 1);
                        }}
                        onChange={({ target }) => {
                            const confirmation = confirmationCode;
                            confirmation[1] = target.value;
                            setConfirmationCode(confirmation);
                            if (target.value.length >= 1) {
                                confirm3.current.focus();
                            }
                        }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={confirmationCode[1]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3} style={{ paddingRight: 5 }}>
                    <TextField
                        margin="normal"
                        id="confirmation-2"
                        variant="outlined"
                        type="number"
                        inputRef={confirm3}
                        style={{ paddingRight: 5 }}
                        onInput={({ target }) => {
                            target.value = Math.max(0, parseInt(target.value)).toString().slice(0, 1);
                        }}
                        onChange={({ target }) => {
                            const confirmation = confirmationCode;
                            confirmation[2] = target.value;
                            setConfirmationCode(confirmation);
                            if (target.value.length >= 1) {
                                confirm4.current.focus();
                            }
                        }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={confirmationCode[2]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        inputRef={confirm4}
                        margin="normal"
                        id="confirmation-3"
                        variant="outlined"
                        type="number"
                        style={{ paddingRight: 5 }}
                        onInput={({ target }) => {
                            target.value = Math.max(0, parseInt(target.value)).toString().slice(0, 1);
                        }}
                        onChange={({ target }) => {
                            const confirmation = confirmationCode;
                            confirmation[3] = target.value;
                            setConfirmationCode(confirmation);
                        }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={confirmationCode[3]}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = ({ demands }) => ({
    requirements: demands.demandAccount.requirements,
    isFullTime: demands.demandAccount.isFullTime,
    expectedCode: demands.demandAccount.confirmationCode
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ saveDemandData, cleanConfirmationCode, showSnackbar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalUserData));