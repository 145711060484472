import React from 'react';
import MaskedInput from 'react-text-mask';

export default function PhoneNumberField(props) {
    const { inputRef, mask, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                if (ref && ref.inputElement) {
                    inputRef(ref.inputElement); // Material-UI espera o ref no nível do DOM
                }
            }}
            mask={mask}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
